import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Input, message, Radio, Space, Checkbox } from 'antd';
import { ApiHelpers } from '../helpers';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { appIcon } from '../const';

const { TextArea } = Input;

const questionsList = [
    {
        question: 'Ce varsta ai?',
        answers: ['18-25', '26-30', '31-35', '36-40', '41-50', '51-60', '>60'],
    },
    {
        question: 'Sunteti',
        answers: ['Femeie', 'Barbat', 'Fluid Gender'],
    },
    {
        question: 'Care este starea ta civila?',
        answers: ['Singur/a', 'Intr-o relatie', 'Casatorit/a'],
    },
    {
        question: 'Ai copii?',
        answers: ['Da', 'Nu'],
    },
    {
        question: 'Care este ultima forma de educatie absolvita?',
        answers: ['Scoala primara/gimnaziu', 'Liceu / colegiu', 'Facultate', 'Nu stiu/Nu raspund'],
    },
    {
        question: 'Ai un animal de companie?',
        hint: 'Te rugam sa alegi toate variantele de raspuns care ti se potrivesc.',
        answers: ['Caine', 'Pisica', 'Pesti exotici', 'Pasari exotice', 'Nu'],
        multiple: true,
    },
    {
        question: 'De unde te informezi in legatura cu evenimentele la care participi?',
        hint: 'Te rugam sa alegi toate variantele de raspuns care ti se potrivesc.',
        answers: ['Social Media', 'Radio', 'Prieteni/familie', 'Website/banners', 'TV', 'Nu stiu/nu imi amintesc'],
        multiple: true,
    },
    {
        question: ' Iti place sa mergi la evenimente:',
        hint: 'Te rugam sa alegi toate variantele de raspuns care ti se potrivesc.',
        answers: ['Singur/a', 'Cu prieteni/familie', 'Cu sotul/sotia', 'Cu copilul/copiii'],
    },
    {
        question: 'Ce te motiveaza sa mergi la evenimente?',
        hint: 'Te rugam sa alegi toate variantele de raspuns care ti se potrivesc.',
        answers: ['Sa ma intalnesc cu prietenii', 'Sa ma bucur de mancare', 'Sa incerc lucruri noi', 'Sa ma bucur de atmosfera de festival'],
        multiple: true,
    },
    {
        question: 'De unde iti faci cumparaturile?',
        hint: 'Te rugam sa alegi toate variantele de raspuns care ti se potrivesc.',
        answers: ['Auchan', 'Carrefour', 'Kaufland', 'Lidl', 'Mega Image', 'Penny', 'Profi', 'Selgros'],
        multiple: true,
    },
    {
        question: 'Ce iti place sa bei la festival?',
        hint: 'Te rugam sa alegi toate variantele de raspuns care ti se potrivesc.',
        answers: ['Bere', 'Apa', 'Suc/Limonada', 'Cafea', 'Vin', 'Cocktails'],
        multiple: true,
    },
    {
        question: 'Esti fumator?',
        answers: ['Da', 'Nu'],
        multiple: false,
    },
];

const FeedbackEvent = (props) => {
    const { t } = useTranslation();
    const {
        restaurantDetails,
        isModalFeedbackVisible,
        setIsModalFeedbackVisible,
        onClose,
        isFeedbackSubmitted,
        setIsFeedbackSubmitted,
        setFeedbackSubmittedTimestamp,
        storageFeedbackSubmitted,
        storageFeedbackSubmittedTimestamp,
        decoded,
    } = props;
    const INITIAL_FEEDBACK_MODEL = {
        restaurantToken: restaurantDetails.uniqueIdentificationToken,
        score: '',
        foodScore: '',
        waiterScore: '',
        uuid: '',
        feedbackMessage: '',
        tableToken: decoded.TableCode,
    };
    const [feedbackContent, setFeedbackContent] = useState({ ...INITIAL_FEEDBACK_MODEL });
    const [surveyResponses, setSurveyResponses] = useState([]);
    const [surveyValidation, setSurveyValidation] = useState(false);
    const responseLetters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm'];
    const [isSurveySubmitted, setIsSurveySubmitted] = useState(
        localStorage.getItem('surveySubmitted_' + restaurantDetails.uniqueIdentificationToken) || false
    );

    useEffect(() => {
        if (isSurveySubmitted) {
        }
    }, [isModalFeedbackVisible, isSurveySubmitted]);

    const onSubmitFeedback = () => {
        ApiHelpers.submitSurvey(restaurantDetails.uniqueIdentificationToken, surveyResponses.join(';')).then(
            (response) => {
                if (response.status === 200) {
                    localStorage.setItem('surveySubmitted_' + restaurantDetails.uniqueIdentificationToken, JSON.stringify(true));
                    localStorage.setItem('surveyReceivedCode_' + restaurantDetails.uniqueIdentificationToken, response.data);
                    setIsSurveySubmitted(true);
                    //message.success(t('ID_POFTIGO_FEEDBACK_SUBMIT_SUCCESS'));
                }
            },
            (error) => {
                message.error(t('ID_POFTIGO_ERROR_MESSAGE') + '. Chestionarul poate fi trimis doar in trimpul festivalului.');
            }
        );
    };

    const onChangeCheckbox = (checkedValues, index) => {
        const tempSurveyResponses = [...surveyResponses];
        tempSurveyResponses[index] =
            checkedValues.length > 0
                ? index +
                  1 +
                  ':' +
                  checkedValues
                      .sort()
                      .map((d) => responseLetters[d])
                      .toString()
                : '';
        setSurveyResponses(tempSurveyResponses);
    };

    const onChangeRadio = (e, index) => {
        const tempSurveyResponses = [...surveyResponses];
        tempSurveyResponses[index] = index + 1 + ':' + responseLetters[e.target.value];
        setSurveyResponses(tempSurveyResponses);
    };

    useEffect(() => {
        let validation = surveyValidation;
        if (surveyResponses.length < questionsList.length) validation = false;
        else if (surveyResponses.indexOf('') !== -1 || surveyResponses.includes(undefined)) validation = false;
        else validation = true;
        setSurveyValidation(validation);
    }, [surveyResponses]);

    return (
        <>
            <Row className="feedback-container survey-container">
                {!isSurveySubmitted ? (
                    <Col>
                        <p>Răspunde la cele 12 întrebări și vei primi un cod QR cu care poți ridica premiul de la case</p>
                        {questionsList.map((d, index) => {
                            return (
                                <div className="survey-question-container" key={'surveyQuestion-' + index}>
                                    <h3>{index + 1 + '. ' + d.question}</h3>
                                    {d.multiple === true ? <div className="survey-question-hint">{d.hint}</div> : null}
                                    {!d.multiple ? (
                                        <Radio.Group name={'surveyQuestion-' + index} onChange={(e) => onChangeRadio(e, index)}>
                                            {d.answers.map((answer, indexAnswer) => {
                                                return (
                                                    <Space direction="vertical" key={'surveyQuestion-' + index + indexAnswer}>
                                                        <Radio value={indexAnswer}>{answer}</Radio>
                                                    </Space>
                                                );
                                            })}
                                        </Radio.Group>
                                    ) : (
                                        <Checkbox.Group
                                            name={'surveyQuestion-' + index}
                                            onChange={(checkedValues) => onChangeCheckbox(checkedValues, index)}>
                                            {d.answers.map((answer, indexAnswer) => {
                                                return (
                                                    <Space direction="vertical" key={'surveyQuestion-' + index + indexAnswer}>
                                                        <Checkbox value={indexAnswer}>{answer}</Checkbox>
                                                    </Space>
                                                );
                                            })}
                                        </Checkbox.Group>
                                    )}
                                </div>
                            );
                        })}

                        <Button
                            type="primary"
                            size="large"
                            onClick={onSubmitFeedback}
                            className="buttonModalClose buttomSubmit"
                            disabled={!surveyValidation}>
                            {t('ID_POFTIGO_SUBMIT')}
                        </Button>
                    </Col>
                ) : (
                    <Col>
                        Mulțumim pentru răspunsuri! Prezintă codul de mai jos la bar pentru a-ți ridică berea
                        <div className="spacingTopMedium qrCode-container">
                            <QRCode
                                value={localStorage.getItem('surveyReceivedCode_' + restaurantDetails.uniqueIdentificationToken)}
                                size={300}
                                level="Q"
                                fgColor="#292333"
                                renderAs="svg"
                                imageSettings={{
                                    src: appIcon,
                                    width: 80,
                                    height: 80,
                                    excavate: true,
                                }}
                            />
                        </div>
                        <div className="text-small">Meniu interactiv powered by <strong>Poftigo</strong></div>
                    </Col>
                )}
            </Row>
        </>
    );
};

export default FeedbackEvent;
