import './App.css';
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as ROUTES from './const/routes';
import Home from './components/Home';
import { firebaseConfig } from './config';
import LocationDetail from './components/LocationDetail';
import LocationEmbed from './components/LocationEmbed';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import i18n from './config/i18n';
import LocationLight from './components/LocationLight';
import Event from './components/Event';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
    const readItemFromStorage = async () => {
        // getter
        if (localStorage.getItem('language')) i18n.changeLanguage(localStorage.getItem('language'));
    };

    useEffect(() => {
        readItemFromStorage();
    }, []);
    return (
        <RecoilRoot>
            <Router>
                <Routes>
                    <Route path={ROUTES.LOCATION_PAGE + ':locationId'} element={<LocationDetail />}></Route>
                    <Route path={ROUTES.EMBED_PAGE + ':locationId'} element={<LocationEmbed />}></Route>
                    <Route path={ROUTES.LOCATION_LIGHT_PAGE + ':locationId'} element={<LocationLight />}></Route>
                    <Route path={ROUTES.EVENT + ':locationId'} element={<Event />}></Route>
                    <Route path="/" element={<Home />}></Route>
                </Routes>
            </Router>
        </RecoilRoot>
    );
}

export default App;
