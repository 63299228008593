import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import { ApiHelpers } from '../helpers';
import { useTranslation } from 'react-i18next';
import { firebaseConfig } from '../config';
import iconAppStore from '../assets/img/appStore.svg';
import iconPlayStore from '../assets/img/playStore.png';
import { BellOutlined, StarOutlined, CheckCircleOutlined, FileTextOutlined, CreditCardOutlined } from '@ant-design/icons';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const DrawerWelcomeAppetizer = (props) => {
    const { t } = useTranslation();
    const {
        restaurantDetails,
        handleClickStore,
        textSize,
        decoded,
        onClose,
        type,
        setIsModalFeedbackVisible,
        feedbackSubmittedTimestamp,
        setFeedbackSubmittedTimestamp,
        storageFeedbackSubmitted,
        storageFeedbackSubmittedTimestamp,
        isFeedbackSubmitted,
        setIsFeedbackSubmitted,
        timeLeftSubmitFeedback,
        setTimeLeftSubmitFeedback,
        setIsModalCallWaiterVisible,
        callWaiterLoading,
        setCallWaiterLoading,
        storageWaiterCalled,
        storageWaiterCalledTimestamp,
        isWaiterCalled,
        setIsWaiterCalled,
        waiterCalledTimestamp,
        setWaiterCalledTimestamp,
        setIsModalRequestPaymentVisible,
        timeLeftRequestPayment,
        setTimeLeftRequestPayment,
        requestPaymentTimestamp,
        storageRequestPayment,
        setIsRequestPayment,
        storageRequestPaymentTimestamp,
        setRequestPaymentTimestamp,
        isRequestPayment,
    } = props;

    const timeoutCallWaiter = 30000;
    const timeoutSubmitFeedback = 480000; // 8 minute
    const timeoutRequestPayment = 300000; // 5 minute

    const calculateTimeLeft = (timestamp, timeout, storageKey, setFlag, storageKey2, setFlag2) => {
        let timeLeft = {};
        if (timestamp && timestamp instanceof Date) {
            const difference = timestamp.getTime() + timeout - new Date().getTime();
            if (difference > 0) {
                timeLeft = {
                    hours: Math.floor(difference / (1000 * 60 * 60)),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60),
                };

                if (Object.keys(timeLeft).length === 0) {
                    setFlag(false);
                    localStorage.setItem(storageKey, JSON.stringify(false));
                    setFlag2(false);
                    localStorage.setItem(storageKey2, JSON.stringify(false));
                }
            } else {
                setFlag(false);
                localStorage.setItem(storageKey, JSON.stringify(false));
                setFlag2(false);
                localStorage.setItem(storageKey2, JSON.stringify(false));
            }
        }
        return timeLeft;
    };

    const [timeLeftCallWaiter, setTimeLeftCallWaiter] = useState(() =>
        calculateTimeLeft(
            waiterCalledTimestamp,
            timeoutCallWaiter,
            storageWaiterCalled,
            setIsWaiterCalled,
            storageWaiterCalledTimestamp,
            setWaiterCalledTimestamp
        )
    );

    useEffect(() => {
        if (props) {
            setTimeLeftSubmitFeedback(() =>
                calculateTimeLeft(
                    feedbackSubmittedTimestamp,
                    timeoutSubmitFeedback,
                    storageFeedbackSubmitted,
                    setIsFeedbackSubmitted,
                    storageFeedbackSubmittedTimestamp,
                    setFeedbackSubmittedTimestamp
                )
            );

            setTimeLeftRequestPayment(() =>
                calculateTimeLeft(
                    requestPaymentTimestamp,
                    timeoutRequestPayment,
                    storageRequestPayment,
                    setIsRequestPayment,
                    storageRequestPaymentTimestamp,
                    setRequestPaymentTimestamp
                )
            );
        }
    }, []);

    useEffect(() => {
        let timer1, timer2, timer3;
        if (isWaiterCalled) {
            timer1 = setTimeout(() => {
                //console.log("Is waiter called:",isWaiterCalled, waiterCalledTimestamp);
                setTimeLeftCallWaiter(() =>
                    calculateTimeLeft(
                        waiterCalledTimestamp,
                        timeoutCallWaiter,
                        storageWaiterCalled,
                        setIsWaiterCalled,
                        storageWaiterCalledTimestamp,
                        setWaiterCalledTimestamp
                    )
                );
            }, 1000);
        }
        if (isFeedbackSubmitted) {
            timer2 = setTimeout(() => {
                setTimeLeftSubmitFeedback(() =>
                    calculateTimeLeft(
                        feedbackSubmittedTimestamp,
                        timeoutSubmitFeedback,
                        storageFeedbackSubmitted,
                        setIsFeedbackSubmitted,
                        storageFeedbackSubmittedTimestamp,
                        setFeedbackSubmittedTimestamp
                    )
                );
            }, 1000);
        }
        if (isRequestPayment) {
            timer3 = setTimeout(() => {
                setTimeLeftRequestPayment(() =>
                    calculateTimeLeft(
                        requestPaymentTimestamp,
                        timeoutRequestPayment,
                        storageRequestPayment,
                        setIsRequestPayment,
                        storageRequestPaymentTimestamp,
                        setRequestPaymentTimestamp
                    )
                );
            }, 1000);
        }
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
        };
    });

    const onCallWaiterMessage = () => {
        onClose();
        setIsModalCallWaiterVisible(true);
    };

    const handleOpenFeedback = () => {
        onClose();
        setIsModalFeedbackVisible(true);
    };

    const handleOpenRequestPayment = () => {
        onClose();
        setIsModalRequestPaymentVisible(true);
    };

    return (
        <>
            <Row justify="left">
                {restaurantDetails.sysRestaurantType !== 'SelfService' &&
                (restaurantDetails.subscriptionPlan === 'Full' ||
                    restaurantDetails.subscriptionPlan === 'Appetizer' ||
                    restaurantDetails.subscriptionPlan === 'AppetizerF') ? (
                    <Col>
                        <Row justify="space-between" gutter={10}>
                            <Col>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={onClose}
                                    className="buttonModalClose buttonAction"
                                    icon={<FileTextOutlined />}>
                                    {t('ID_POFTIGO_BROWSE_MENU')}
                                </Button>
                            </Col>
                            {restaurantDetails.subscriptionPlan !== 'AppetizerF' ? (
                                <Col>
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={onCallWaiterMessage}
                                        disabled={isWaiterCalled && timeLeftCallWaiter.seconds > 0 ? true : false}
                                        className="buttonModalClose buttonAction"
                                        icon={isWaiterCalled && timeLeftCallWaiter.seconds > 0 ? <CheckCircleOutlined /> : <BellOutlined />}
                                        loading={callWaiterLoading ? true : false}>
                                        {isWaiterCalled && timeLeftCallWaiter.seconds > 0
                                            ? t('ID_POFTIGO_CALL_WAITER_SUCCESS')
                                            : t('ID_POFTIGO_CALL_WAITER')}
                                    </Button>
                                </Col>
                            ) : null}

                            {restaurantDetails.subscriptionPlan !== 'AppetizerF' ? (
                                <Col>
                                    <Button
                                        type="primary"
                                        size="large"
                                        disabled={isRequestPayment && timeLeftRequestPayment.seconds > 0 ? true : false}
                                        onClick={handleOpenRequestPayment}
                                        className="buttonModalClose buttonAction"
                                        icon={
                                            isRequestPayment && timeLeftRequestPayment.seconds > 0 ? <CheckCircleOutlined /> : <CreditCardOutlined />
                                        }>
                                        {isRequestPayment && timeLeftRequestPayment.seconds > 0
                                            ? t('ID_POFTIGO_REQUEST_PAYMENT_SUCCESS')
                                            : t('ID_POFTIGO_REQUEST_PAYMENT')}
                                    </Button>
                                </Col>
                            ) : null}
                            <Col>
                                <Button
                                    type="primary"
                                    size="large"
                                    disabled={isFeedbackSubmitted && timeLeftSubmitFeedback.seconds > 0 ? true : false}
                                    onClick={handleOpenFeedback}
                                    className="buttonModalClose buttonAction"
                                    icon={isFeedbackSubmitted && timeLeftSubmitFeedback.seconds ? <CheckCircleOutlined /> : <StarOutlined />}>
                                    {isFeedbackSubmitted && timeLeftSubmitFeedback.seconds > 0
                                        ? t('ID_POFTIGO_FEEDBACK_SUCCESS')
                                        : t('ID_POFTIGO_FEEDBACK')}
                                </Button>
                            </Col>

                            {isWaiterCalled && timeLeftCallWaiter.seconds > 0 ? <Col></Col> : null}
                        </Row>
                    </Col>
                ) : null}
            </Row>
            {restaurantDetails.subscriptionPlan === 'Full' ? (
                <Row>
                    <Col>
                        <h2 className={textSize === 'small' ? 'drawerHeading small' : 'drawerHeading'}>
                            {restaurantDetails.sysRestaurantType !== 'SelfService'
                                ? t('ID_POFTIGO_DOWNLOAD_APP_TEXT')
                                : t('ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF1')}
                        </h2>
                        <div className="storeIconContainer">
                            <a
                                href="https://apps.apple.com/us/app/poftigo/id1574336637"
                                target="blank"
                                className="storeIcon"
                                onClick={() => handleClickStore('apple')}>
                                <img title="Pofigo in App Store" src={iconAppStore} alt="Pofigo in App Store" />
                            </a>
                            {t('ID_POFTIGO_OR')}
                            <a
                                href="https://play.google.com/store/apps/details?id=ro.gourmettech.poftigo"
                                target="blank"
                                onClick={() => handleClickStore('google')}>
                                <img alt="Get it on Google Play" title="Poftigo in Google Play" src={iconPlayStore} height={60} />
                            </a>
                        </div>
                        {restaurantDetails.sysRestaurantType === 'SelfService' ? (
                            <h2 className={textSize === 'small' ? 'small' : null}>{t('ID_POFTIGO_DOWNLOAD_APP_TEXT_SELF2')}</h2>
                        ) : null}
                    </Col>
                </Row>
            ) : null}
            {type !== 'menuItem' ? (
                <>
                    <div className={restaurantDetails.subscriptionPlan !== 'Free' ? 'drawerFooterRestaurant bordered' : 'drawerFooterRestaurant'}>
                        <Row gutter={20} align="middle">
                            <Col>
                                {restaurantDetails.logoPath ? (
                                    <img
                                        src={ApiHelpers.getImageLink(restaurantDetails.logoPath)}
                                        title={restaurantDetails.restaurantName}
                                        alt={restaurantDetails.restaurantName}
                                        className="logoImagePublicDrawer"
                                    />
                                ) : null}
                            </Col>
                            <Col>
                                <Row justify="space-between">
                                    <Col>
                                        <h3>{restaurantDetails.restaurantName}</h3>
                                    </Col>
                                </Row>
                                {restaurantDetails.sysRestaurantType !== 'SelfService' ? (
                                    <Row>
                                        <Col>
                                            {t('ID_POFTIGO_TABLE_LABEL')} {decoded.TableIndex}
                                        </Col>
                                    </Row>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default DrawerWelcomeAppetizer;
