import React, { useEffect } from 'react';

const Home = () => {
    useEffect(() => {
        window.location.href = "https://www.poftigo.ro";
    }, []);

    return (
        <div>Poftigo</div>
    )
}

export default Home;