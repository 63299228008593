import base64 from 'react-native-base64'

const base64UrlToBase64 = (value) => {
    var base64Str = value.replace(/-/g, "+").replace(/_/g, "/");
    if (base64Str.length % 4 == 3)
        base64Str = base64Str + "="
    else if (base64Str.length % 4 == 2)
        base64Str = base64Str + "=="
    else if (base64Str.length % 4 == 1)
        base64Str = base64Str + "==="

    return base64Str;
}

const decrypt = (encryptedStr) => {
    try {
        //reconstruct base64 string from base64url
        var encryptedValue = base64UrlToBase64(encryptedStr)
        //convert to byte array
        const encryptedArray = base64.decode(encryptedValue);
        var startIndex = encryptedArray.length - 32;
        //aes key length is 16 
        var key = encryptedArray.slice(startIndex, startIndex + 16)
        //aes iv length is 16 
        var iv = encryptedArray.slice(startIndex + 16, startIndex + 32)
        //the actual content 
        var cipher = encryptedArray.slice(0, startIndex);
        var encryptedBytes = Uint8Array.from(cipher, c => c.charCodeAt(0));

        //decrypt
        var aesjs = require('aes-js');
        var aesCbc = new aesjs.ModeOfOperation.cbc(Uint8Array.from(key, c => c.charCodeAt(0)), Uint8Array.from(iv, c => c.charCodeAt(0)));
        var decryptedBytes = aesCbc.decrypt(encryptedBytes);
        decryptedBytes = aesjs.padding.pkcs7.strip(decryptedBytes);
        var decryptedStr = aesjs.utils.utf8.fromBytes(decryptedBytes);

        return decryptedStr;
    } catch (e) {
        return false;
    }
};

const Decryptor = (value) => {
    return decrypt(value);
};

export default Decryptor;