import React from 'react';
import { Col, Row, Affix } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftCircleFilled } from '@ant-design/icons';

const MenuCategoryNav = (props) => {
    const { menuCategory, handleClickCategory } = props;
    const navigate = useNavigate();

    return (
        <Affix>
            <Row className="navCategory-row">
                <Col>
                    <LeftCircleFilled
                        className="navCategoryIcon"
                        onClick={() => {
                            handleClickCategory();
                        }}
                    />
                </Col>
                <Col>
                    <h2 className="subTitle navCategory">{menuCategory}</h2>
                </Col>
            </Row>
        </Affix>
    );
};

export default MenuCategoryNav;
