import { bacchusAPIEndpoint, bacchusAPIUploadsPath, activeEnvironment } from '../config';
import axios from 'axios';
import i18n from '../config/i18n';
const languageCodeTranslation = (langCode) => {
    return langCode.replace('-', '_');
};

// Uploads and media

export const getImageLink = (imagePath) => {
    return bacchusAPIUploadsPath + imagePath.replace('Images\\', '');
};

export const getImageLinkSize = (imagePath, size) => {
    let [filename, extension] = (bacchusAPIUploadsPath + imagePath.replace('Images\\', '')).split('.jpg');
    let rename = filename + '-' + size + '.jpg';
    return rename;
};

export const getRestaurantDetails = (uniqueIdentificationToken, langCode = i18n.language) => {
    return axios(bacchusAPIEndpoint + '/api/menu/' + uniqueIdentificationToken + '/' + languageCodeTranslation(langCode), {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const callWaiter = (restaurantToken, tableToken, message = '') => {
    return axios(bacchusAPIEndpoint + '/api/web/callWaiter', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            tableSessionToken: '',
            uuid: '',
            restaurantToken: restaurantToken,
            tableToken: tableToken,
            message: message,
        },
    });
};

export const submitFeedback = (restaurantToken, feedbackMessage) => {
    return axios(bacchusAPIEndpoint + '/api/web/feedback/' + restaurantToken, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            ...feedbackMessage,
        },
    });
};

export const submitSurvey = (restaurantToken, surveyResponses) => {
    return axios(bacchusAPIEndpoint + '/api/web/survey/' + restaurantToken, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            surveyToken: activeEnvironment === 'dev' ? '9968ABFC-D8A5-4496-9E7A-AD6F67EC1260' : '1A774E5B-01FB-4708-BBEA-F1353C4643AA',
            responses: surveyResponses,
            awardVoucher: false,
            recordStamp: new Date(),
        },
    });
};

export const getRestaurantTable = (hash) => {
    return axios(bacchusAPIEndpoint + '/admin/restaurant/table/getRestaurantTable/' + hash, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const requestBill = (restaurantToken, tableToken, paymentMethod, tip = null) => {
    return axios(bacchusAPIEndpoint + '/api/web/requestBill', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            tableSessionToken: '',
            uuid: '',
            selfOnly: 'false',
            paymentMethod: paymentMethod,
            companyCode: '',
            paymentMethodDetails: '',
            restaurantToken: restaurantToken,
            tableToken: tableToken,
            tip: tip,
        },
    });
};

export const getMenuCategories = (uniqueIdentificationToken, langCode = i18n.language) => {
    return axios(bacchusAPIEndpoint + '/api/menu/getMenuCategories/' + uniqueIdentificationToken + '/' + languageCodeTranslation(langCode), {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const getMenuData = (uniqueIdentificationToken, langCode = i18n.language) => {
    return axios(bacchusAPIEndpoint + '/api/menu/data/' + uniqueIdentificationToken + '/' + languageCodeTranslation(langCode), {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
