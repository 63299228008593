import { ENV } from '../const';
// import our environment variables
require('dotenv').config();

// Set active/current environment
const activeEnvironment = process.env.REACT_APP_ACTIVE_ENVIRONMENT;
const bacchusAPIEndpoint = ENV[activeEnvironment];
const bacchusAPIUploadsPath = ENV[activeEnvironment] + '/tastyimages/';
var firebaseConfig;

if (activeEnvironment === 'dev') {
    firebaseConfig = {
        apiKey: 'AIzaSyB8SyqyqTzhseNcNx_V6Gl-3b77FgGt4uY',
        authDomain: 'bacchus-a14fb.firebaseapp.com',
        databaseURL: 'https://bacchus-a14fb.firebaseio.com',
        projectId: 'bacchus-a14fb',
        storageBucket: 'bacchus-a14fb.appspot.com',
        messagingSenderId: '406072562139',
        appId: '1:406072562139:web:8b03d73583d8d342e197fe',
        measurementId: 'G-356K4PNTWC',
    };
} else if (activeEnvironment === 'test') {
    firebaseConfig = {
        apiKey: 'AIzaSyAndaYadjEuFlPp2k9Cl8JIR3kYOpPfLzw',
        authDomain: 'bacchus-testing.firebaseapp.com',
        projectId: 'bacchus-testing',
        storageBucket: 'bacchus-testing.appspot.com',
        messagingSenderId: '460418848217',
        appId: '1:460418848217:web:6e9a2fc55e0e4247a035c8',
        measurementId: 'G-ZDB57T8L2D',
    };
} else if (activeEnvironment === 'live') {
    firebaseConfig = {
        apiKey: 'AIzaSyAmD7yvJXvYM7CxcMXFfXmOcJqql4F6oIo',
        authDomain: 'poftigo-live.firebaseapp.com',
        projectId: 'poftigo-live',
        storageBucket: 'poftigo-live.appspot.com',
        messagingSenderId: '2526457402',
        appId: '1:2526457402:web:f815c83936bb68f0878f03',
        measurementId: 'G-TJ72VEJDPF',
    };
}
export { bacchusAPIEndpoint, bacchusAPIUploadsPath, activeEnvironment, firebaseConfig };
