import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiHelpers, Decryptor, normalizeString, useWindowDimensions, nutritionalMenuFull, formatMenuItemWeight } from '../helpers';
import { Spin, Layout, Row, Col, Drawer, Skeleton, Modal, Table, Tabs } from 'antd';
import ReactMarkdown from 'react-markdown';
import { useRecoilState } from 'recoil';
import MenuCategory from './MenuCategory';
import Search from './Search';
import { ReactComponent as LogoFull } from '../assets/logo-poftigo-full.svg';
import { ReactComponent as LogoMark } from '../assets/svg/logomark-poftigo.svg';
import MenuCategoryNav from './MenuCategoryNav';
import MenuItemEvent from './MenuItemEvent';
import CookieConsent from 'react-cookie-consent';

import { firebaseConfig } from '../config';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';
import i18n from '../config/i18n';
import DrawerWelcome from './DrawerWelcome';
import Feedback from './Feedback';
import DrawerWelcomeAppetizer from './DrawerWelcomeAppetizer';
import CallWaiterMessage from './CallWaiterMessage';
import RequestPayment from './RequestPayment';
import Footer from './Footer';
import flagRomania from '../assets/img/flag-romania.svg';
import flagUK from '../assets/img/flag-uk.svg';
import DrawerWelcomeEvent from './DrawerWelcomeEvent';
import FeedbackEvent from './FeedbackEvent';

const burgerFestParticipants = [
    { title: 'BAZ Burgers', logo: '' },
    { title: 'Burger Van', logo: '' },
    { title: 'Burgerati', logo: '' },
    { title: 'Casa Angus', logo: '' },
    { title: 'Circus Pub', logo: '' },
    { title: 'Fking Burgers', logo: '' },
    { title: 'Furgoneta', logo: '' },
    { title: "Grataru' Meserias", logo: '' },
    { title: 'GYST', logo: '' },
    { title: 'Hard Rock Cafe', logo: '' },
    { title: 'Meat2Eat', logo: '' },
    { title: 'MEAT Inc', logo: '' },
    { title: 'The Millenial', logo: '' },
    { title: 'Ruff Burger', logo: '' },
    { title: 'Sara Green', logo: '' },
    { title: 'Submarine Burger', logo: '' },
    { title: "Yogi's Burger", logo: '' },
];

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const { Footer: AntdFooter, Content } = Layout;

const Event = () => {
    let { locationId } = useParams();
    let location = useLocation();
    let url = location.pathname;
    const { t } = useTranslation();
    const navigate = useNavigate();
    //    const { data: restaurantDetails, isLoading } = useRestaurantDetailsPublicById(locationId);
    const [restaurantDetails, setRestaurantDetails] = useState({});
    const [restaurantDetailsFilter, setRestaurantDetailsFilter] = useState({});
    const [menuCategories, setMenuCategories] = useState([]);
    const [menuCategoriesFilter, setMenuCategoriesFilter] = useState([]);
    const [categorySelected, setCategorySelected] = useState({
        active: false,
        categoryId: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [foundMenuItems, setFoundMenuItems] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFeedbackVisible, setIsModalFeedbackVisible] = useState(false);
    const [isModalCallWaiterVisible, setIsModalCallWaiterVisible] = useState(false);
    const [isModalRequestPaymentVisible, setIsModalRequestPaymentVisible] = useState(false);

    const [selectedMenuItem, setSelectedMenuItem] = useState({});
    const [isFilter, setIsFilter] = useState({});
    const INITIAL_MENU_CATEGORY_TYPE = t('ID_POFTIGO_FOOD_LABEL_ESC');
    const [selectedTag, setSelectedTag] = useState(t('ID_POFTIGO_FOOD_LABEL_ESC'));
    const [isSelfService, setIsSelfService] = useState(false);
    const { height, width } = useWindowDimensions();
    const [decoded, setDecoded] = useState({});

    const [drawerProps, setDrawerProps] = useState({
        visible: false,
        placement: 'bottom',
    });
    const [searchParams, setSearchParams] = useSearchParams();

    // Feedback submitted
    const storageFeedbackSubmitted = 'feedbackSubmitted_' + restaurantDetails.uniqueIdentificationToken;
    const storageFeedbackSubmittedTimestamp = 'feedbackSubmittedTimestamp_' + restaurantDetails.uniqueIdentificationToken;
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [feedbackSubmittedTimestamp, setFeedbackSubmittedTimestamp] = useState(
        new Date(JSON.parse(localStorage.getItem(storageFeedbackSubmittedTimestamp))) || false
    );
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(localStorage.getItem(storageFeedbackSubmitted));
    const [timeLeftSubmitFeedback, setTimeLeftSubmitFeedback] = useState({});

    // Call waiter
    const [callWaiterLoading, setCallWaiterLoading] = useState(false);
    const storageWaiterCalled = 'waiterCalled_' + restaurantDetails.uniqueIdentificationToken;
    const storageWaiterCalledTimestamp = 'waiterCalledTimestamp_' + restaurantDetails.uniqueIdentificationToken;
    const [isWaiterCalled, setIsWaiterCalled] = useState(JSON.parse(localStorage.getItem(storageWaiterCalled)) || false);
    const [waiterCalledTimestamp, setWaiterCalledTimestamp] = useState(
        localStorage.getItem(storageWaiterCalledTimestamp) ? new Date(JSON.parse(localStorage.getItem(storageWaiterCalledTimestamp))) : false
    );

    // Request payment
    const storageRequestPayment = 'requestPayment_' + restaurantDetails.uniqueIdentificationToken;
    const storageRequestPaymentTimestamp = 'requestPaymentTimestamp_' + restaurantDetails.uniqueIdentificationToken;
    const [requestPayment, setRequestPayment] = useState(false);
    const [requestPaymentTimestamp, setRequestPaymentTimestamp] = useState(
        new Date(JSON.parse(localStorage.getItem(storageRequestPaymentTimestamp))) || false
    );
    const [isRequestPayment, setIsRequestPayment] = useState(localStorage.getItem(storageRequestPayment));
    const [timeLeftRequestPayment, setTimeLeftRequestPayment] = useState({});

    const [itemsInMenuCategoryTag, setItemsInMenuCategoryTag] = useState({
        food: 0,
        beverages: 0,
        tobacco: 0,
    });

    const dataSourceNutritional = [
        {
            key: '1',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM1'),
            nutritionalValue: 250,
            type: 'main',
        },
        {
            key: '2',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM2'),
            nutritionalValue: 760,
            type: 'main',
        },
        {
            key: '3',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM3'),
            nutritionalValue: 4,
            type: 'main',
        },
        {
            key: '4',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM4'),
            nutritionalValue: 2,
            type: 'secondary',
        },
        {
            key: '4',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM5'),
            nutritionalValue: 3,
            type: 'main',
        },
        {
            key: '4',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM6'),
            nutritionalValue: 1.5,
            type: 'secondary',
        },
        {
            key: '4',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM7'),
            nutritionalValue: 50,
            type: 'main',
        },
        {
            key: '4',
            nutritionalName: t('ID_POFTIGO_NUTRITION_TERM8'),
            nutritionalValue: 7,
            type: 'main',
        },
    ];

    const listBazar = [
        'Adrianart',
        'AsWoodenTales',
        'Atelier Pam Pam',
        'Bratari Preda',
        'BuzduBijoux',
        'Danika Atelier',
        'Emisart',
        'Festival Shop',
        'IQ PUZZLE',
        'Iuliafacepaint',
        "Li's Henna",
        'Mirian Handmade',
        'Monorean',
        'Nebs',
        'Oko',
        'Posh Tatoos',
        'Pretty Kitty',
        'Purple Lands',
        'Sil Art Deco',
    ];

    const columnsNutritional = [
        {
            title: 'Name',
            dataIndex: 'nutritionalName',
            key: 'nutritionalName',
            render: (text, record) => <span className={'nutritional-name ' + record.type}>{text}</span>,
        },
        {
            title: 'Value',
            dataIndex: 'nutritionalValue',
            key: 'nutritionalValue',
            render: (text) => <span className={'nutritional-value'}>{text}</span>,
            align: 'right',
        },
    ];
    const { TabPane } = Tabs;

    useEffect(() => {
        setIsFeedbackSubmitted(localStorage.getItem(storageFeedbackSubmitted));
    }, [localStorage.getItem(storageFeedbackSubmitted)]);

    useEffect(() => {
        if (location.state && location.state.modalVisible !== true) {
            setIsModalVisible(false);
        }
    }, [location]);

    useEffect(() => {
        setIsLoading(true);
        let params = new URLSearchParams(location.search);
        const hash = /[^/]*$/.exec(locationId)[0];

        ApiHelpers.getRestaurantTable(hash).then(
            (response) => {
                if (response.data.restaurantToken) {
                    setDecoded(JSON.parse(Decryptor(response.data.qrCodeContent)));
                    ApiHelpers.getRestaurantDetails(response.data.restaurantToken, i18n.language).then(
                        (response) => {
                            if (!response.data.message) {
                                document.title = response.data.restaurantName + ' - Poftigo';
                                if (response.data.subscriptionPlan === 'Full' || response.data.subscriptionPlan === 'Appetizer')
                                    setDrawerProps({ ...drawerProps, visible: true });
                                // Log event in Analytics
                                logEvent(analytics, 'page_view', {
                                    page_title: response.data.restaurantName + ' - Poftigo',
                                });
                                setRestaurantDetails(response.data);
                                setRestaurantDetailsFilter(response.data);

                                if (response.data.defaultCategoryType) setSelectedTag(response.data.defaultCategoryType);
                                // console.log(
                                //     i18n.language,
                                //     'Filter after',
                                //     t('ID_POFTIGO_FOOD_LABEL_ESC'),
                                //     response.data.menu[0].category.filter((d) => d.tag === t('ID_POFTIGO_FOOD_LABEL_ESC')),
                                //     response.data.menu[0].category
                                // );
                                setMenuCategories(response.data.menu[0].category.filter((d) => d.englishTag === response.data.defaultCategoryType));
                                setMenuCategoriesFilter(response.data.menu[0].category);
                                setItemsInMenuCategoryTag({
                                    food: response.data.menu[0].category.filter((d) => d.englishTag === 'food').length,
                                    beverages: response.data.menu[0].category.filter((d) => d.englishTag === 'beverages').length,
                                    tobacco: response.data.menu[0].category.filter((d) => d.englishTag === 'tobacco').length,
                                });
                                if (!params.get('tag')) navigate(url + '?tag=' + t('ID_POFTIGO_FOOD_LABEL_ESC'));
                                setIsLoading(false);
                            } else {
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }, [locationId, i18n.language]);

    const showModal = (menuItem) => {
        setIsModalVisible(true);
        navigate(url, { state: { menuItem: menuItem, modalVisible: true } });
        // Log event in Analytics
        logEvent(analytics, 'select_content', {
            content_type: 'menuItem',
            item_id: menuItem.menuItemToken,
        });
        setSelectedMenuItem(menuItem);
    };

    const onClose = () => {
        logEvent(analytics, 'popup_closed');
        setDrawerProps({ ...drawerProps, visible: false });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        navigate(-1);
    };

    useEffect(() => {
        let qsCategoryTag = searchParams.get('tag');
        if (qsCategoryTag === 'mancare') qsCategoryTag = 'food';
        if (qsCategoryTag === 'bauturi') qsCategoryTag = 'beverages';
        if (qsCategoryTag) {
            filterByCategoryTag(qsCategoryTag);
        }
    }, [menuCategoriesFilter, searchParams]);

    const filterByCategoryTag = (tag) => {
        let params = new URLSearchParams(location.search);
        if (params.get('tag') !== tag) navigate(url + '?tag=' + tag);
        logEvent(analytics, 'select_content', {
            content_type: 'menuCategoryTag',
            item_id: tag,
            page_title: restaurantDetails.uniqueIdentificationToken,
        });
        setKeyword('');
        setIsFilter({});
        setCategorySelected({
            active: false,
            categoryId: '',
        });
        setIsSearch(false);
        setFoundMenuItems([]);
        const filteredCategories = menuCategoriesFilter.filter((d) => d.englishTag === tag);

        setSelectedTag(tag);
        setMenuCategories([...filteredCategories]);
    };

    const handleSearch = (keyword) => {
        setIsLoading(true);
        setKeyword(keyword);
        setCategorySelected({
            active: false,
            categoryId: '',
        });
        setIsSearch(true);
        const lowCaseKeyword = normalizeString(keyword);
        let categoryItem = [];
        let foundMenuItems = [];
        // Log event in Analytics
        if (keyword) {
            logEvent(analytics, 'search', {
                search_term: keyword,
                page_title: restaurantDetails.uniqueIdentificationToken,
            });
            // Search and filter menu categories
            let newMenuCategories = menuCategoriesFilter.filter((category) => {
                let categoryItem = [];
                if (category.item) {
                    const filteredItems = category.item.filter((item) => {
                        if (item.title && normalizeString(item.title).indexOf(lowCaseKeyword) > -1) {
                            return true;
                        }
                        return false;
                    });
                    foundMenuItems = [...foundMenuItems, ...filteredItems];
                    if (filteredItems.length > 0) return true;
                }
                if (normalizeString(category.categoryName).indexOf(lowCaseKeyword) > -1)
                    // If found in menu category name
                    return true;
                return false;
            });

            setFoundMenuItems(foundMenuItems);
            setMenuCategories(newMenuCategories);
            setIsLoading(false);
        } else {
            filterByCategoryTag(selectedTag);
            setIsLoading(false);
            setIsSearch(false);
        }
    };

    const handleClickCategory = (menuCategoryToken, categoryName) => {
        window.scrollTo(0, 0);
        navigate(url, { state: { categoryName: categoryName, modalVisible: false } });
        if (menuCategoryToken) {
            setCategorySelected({
                active: true,
                categoryId: menuCategoryToken,
                categoryName: categoryName,
            });
            let foundMenuItems = [];
            menuCategoriesFilter.map((category) => {
                if (category.menuCategoryToken === menuCategoryToken)
                    if (category.item) {
                        // Log event in Analytics
                        logEvent(analytics, 'select_content', {
                            content_type: 'menuCategory',
                            item_id: menuCategoryToken,
                        });

                        foundMenuItems = category.item.map((item) => {
                            return item;
                        });
                    }
            });
            setFoundMenuItems(foundMenuItems);
        } else {
            setCategorySelected({
                active: false,
                categoryId: '',
                categoryName: '',
            });
            handleSearch(keyword);
        }
    };

    const refreshPage = () => {
        setIsLoading(true);
        navigate(url, { state: 'home' });
        if (restaurantDetails.subscriptionPlan === 'Full' || restaurantDetails.subscriptionPlan === 'Appetizer')
            setDrawerProps({ ...drawerProps, visible: true });
        setCategorySelected({
            active: false,
            categoryId: '',
        });
        handleSearch('');
        setTimeout(function () {
            setIsLoading(false);
        }, 100);
    };

    const handleClickStore = (store) => {
        // Log event in Analytics
        logEvent(analytics, 'select_content', {
            content_type: 'button',
            item_id: store,
        });
    };

    const handleChangeLanguage = (e, langCode) => {
        e.preventDefault();
        // setter
        localStorage.setItem('language', langCode);
        i18n.changeLanguage(langCode);
        refreshPage();
    };

    const menuItemContent = (
        <>
            <div className="menuItemDetails">
                {selectedMenuItem.itemImagePath ? (
                    <img
                        src={ApiHelpers.getImageLink(selectedMenuItem.itemImagePath)}
                        alt={selectedMenuItem.title}
                        title={selectedMenuItem.title}
                        className="menuItemImage modal"
                    />
                ) : null}
                <Row gutter={[20, 20]} justify="space-between">
                    <Col span={18}>
                        {selectedMenuItem.listOfIngredients && selectedMenuItem.listOfIngredients.length > 0 ? (
                            <div>{nutritionalMenuFull(selectedMenuItem.listOfIngredients, selectedMenuItem, true)}</div>
                        ) : (
                            <p className="description">
                                <ReactMarkdown children={selectedMenuItem.itemDescription} />
                            </p>
                        )}
                        {selectedMenuItem.ingredients && selectedMenuItem.listOfIngredients === null && selectedMenuItem.ingredients.length > 0 ? (
                            <>
                                <ReactMarkdown children={selectedMenuItem.itemDescription}></ReactMarkdown>
                                <h2 className="sectionTitle">{t('ID_POFTIGO_INGREDIENTS')}</h2>
                                <div>{selectedMenuItem.ingredients.replaceAll(',', ', ')}</div>
                            </>
                        ) : null}
                        {selectedMenuItem.allergens && selectedMenuItem.listOfIngredients === null && selectedMenuItem.allergens.length > 0 ? (
                            <>
                                <h2 className="sectionTitle">{t('ID_POFTIGO_ALLERGENS')}</h2>

                                {selectedMenuItem.allergens ? selectedMenuItem.allergens : null}
                            </>
                        ) : null}
                    </Col>
                    <Col span={6} align="right">
                        <p className="price">{selectedMenuItem.price} lei</p>
                        {selectedMenuItem.weight ? <span>({formatMenuItemWeight(selectedMenuItem.weight)})</span> : null}
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        {selectedMenuItem.groups
                            ? selectedMenuItem.groups.map((group) => {
                                  return (
                                      <div key={group.menuItemGroupToken}>
                                          <h3 className="auxiliaryGroup">{group.displayGroupName}</h3>
                                          {group.items
                                              ? group.items.map((item, index) => {
                                                    return (
                                                        <Row gutter={[20, 20]} justify="center" key={item.uniqueIdentificationToken}>
                                                            <Col span={18}>{item.menuItemName}</Col>
                                                            <Col span={6} align="right">
                                                                {item.price > 0 ? <span>+ {item.price} lei</span> : null}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                              : null}
                                      </div>
                                  );
                              })
                            : null}
                    </Col>
                </Row>
            </div>
            {restaurantDetails.subscriptionPlan === 'Full' ? (
                <>
                    <h2 className="drawerFooter highlight">{t('ID_POFTIGO_ORDER_INVITATION')}</h2>
                    <DrawerWelcome
                        restaurantDetails={restaurantDetails}
                        handleClickStore={handleClickStore}
                        textSize="small"
                        decoded={decoded}
                        type="menuItem"
                    />
                </>
            ) : null}
        </>
    );

    const listFoundMenuItemsByVendor = (foundMenuItems) => {
        let currentVendor = '';
        let showCurrentVendor = false;
        if (foundMenuItems.length > 0) {
            return foundMenuItems.map((d, index) => {
                showCurrentVendor = true;
                if (index === 0) {
                    currentVendor = d.title.indexOf('#') > -1 ? d.title.substring(0, d.title.indexOf(' #')) : null;
                    showCurrentVendor = true;
                } else if (currentVendor === (d.title.indexOf('#') > -1 ? d.title.substring(0, d.title.indexOf(' #')) : null)) {
                    showCurrentVendor = false;
                } else {
                    currentVendor = d.title.indexOf('#') > -1 ? d.title.substring(0, d.title.indexOf(' #')) : null;
                }
                return (
                    <>
                        <MenuItemEvent
                            menuItem={d}
                            key={'menuItem' + index}
                            showModal={showModal}
                            showCurrentVendor={showCurrentVendor}
                            currentVendor={currentVendor}
                            index={index}
                            setIsModalFeedbackVisible={setIsModalFeedbackVisible}
                            restaurantDetails={restaurantDetails}
                        />
                    </>
                );
            });
        } else if (categorySelected.categoryName === 'Bazar') {
            return listBazar.map((d, index) => {
                return (
                    <Col span={24} key={'elementBazar-' + index} className="restaurantMenuContainer">
                        <h3>{d}</h3>
                    </Col>
                );
            });
        }
    };

    return (
        <Layout>
            <CookieConsent
                buttonText={t('ID_POFTIGO_COOKIE_ACCEPT')}
                buttonClasses="cookie-banner-button"
                containerClasses="cookie-banner"
                contentClasses="cookie-banner-inner"
                overlay={true}>
                {t('ID_POFTIGO_COOKIE_MESSAGE')}
                <p>
                    {t('ID_POFTIGO_COOKIE_MORE')}{' '}
                    <a href="https://www.poftigo.ro/politica-de-confidentialitate/" title="Cookies" target="blank">
                        {t('ID_POFTIGO_COOKIE_MORE_LINK')}
                    </a>
                </p>
            </CookieConsent>
            <Content className="restaurantContainer">
                <Drawer
                    title={
                        <Row gutter={[16, 16]}>
                            
                        </Row>
                    }
                    placement={drawerProps.placement}
                    closable={true}
                    maskClosable={true}
                    onClose={onClose}
                    visible={drawerProps.visible}
                    height="400px">
                    {restaurantDetails.sysRestaurantType !== 'SelfService' &&
                    (restaurantDetails.subscriptionPlan === 'Appetizer' ||
                        restaurantDetails.subscriptionPlan === 'Full' ||
                        restaurantDetails.subscriptionPlan === 'AppetizerF') ? (
                        <DrawerWelcomeAppetizer
                            restaurantDetails={restaurantDetails}
                            handleClickStore={handleClickStore}
                            decoded={decoded}
                            onClose={onClose}
                            setIsModalFeedbackVisible={setIsModalFeedbackVisible}
                            setIsModalCallWaiterVisible={setIsModalCallWaiterVisible}
                            storageFeedbackSubmitted={storageFeedbackSubmitted}
                            storageFeedbackSubmittedTimestamp={storageFeedbackSubmittedTimestamp}
                            feedbackSubmittedTimestamp={new Date(JSON.parse(localStorage.getItem(storageFeedbackSubmittedTimestamp))) || false}
                            setFeedbackSubmittedTimestamp={setFeedbackSubmittedTimestamp}
                            isFeedbackSubmitted={JSON.parse(localStorage.getItem(storageFeedbackSubmitted))}
                            setIsFeedbackSubmitted={setIsFeedbackSubmitted}
                            timeLeftSubmitFeedback={timeLeftSubmitFeedback}
                            setTimeLeftSubmitFeedback={setTimeLeftSubmitFeedback}
                            callWaiterLoading={callWaiterLoading}
                            setCallWaiterLoading={setCallWaiterLoading}
                            storageWaiterCalled={storageWaiterCalled}
                            storageWaiterCalledTimestamp={storageWaiterCalledTimestamp}
                            isWaiterCalled={JSON.parse(localStorage.getItem(storageWaiterCalled)) || false}
                            setIsWaiterCalled={setIsWaiterCalled}
                            waiterCalledTimestamp={new Date(JSON.parse(localStorage.getItem(storageWaiterCalledTimestamp))) || false}
                            setWaiterCalledTimestamp={setWaiterCalledTimestamp}
                            setIsModalRequestPaymentVisible={setIsModalRequestPaymentVisible}
                            timeLeftRequestPayment={timeLeftRequestPayment}
                            setTimeLeftRequestPayment={setTimeLeftRequestPayment}
                            requestPaymentTimestamp={new Date(JSON.parse(localStorage.getItem(storageRequestPaymentTimestamp))) || false}
                            storageRequestPayment={storageRequestPayment}
                            setIsRequestPayment={setIsRequestPayment}
                            storageRequestPaymentTimestamp={storageRequestPaymentTimestamp}
                            setRequestPaymentTimestamp={setRequestPaymentTimestamp}
                            isRequestPayment={JSON.parse(localStorage.getItem(storageRequestPayment))}
                        />
                    ) : (
                        <DrawerWelcomeEvent
                            restaurantDetails={restaurantDetails}
                            handleClickStore={handleClickStore}
                            decoded={decoded}
                            onClose={onClose}
                            showTable={false}
                            setIsModalFeedbackVisible={setIsModalFeedbackVisible}
                        />
                    )}
                </Drawer>
                {width > 600 ? (
                    <Modal
                        title={selectedMenuItem.title}
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        closable={true}
                        height={window.innerHeight - 200}
                        footer={null}
                        maskClosable={true}>
                        {menuItemContent}
                    </Modal>
                ) : (
                    <Drawer
                        title={selectedMenuItem.title}
                        placement="bottom"
                        closable={true}
                        onClose={handleCancel}
                        visible={isModalVisible}
                        height={window.innerHeight - 150}>
                        {menuItemContent}
                    </Drawer>
                )}
                <Drawer
                    title={<div className="ant-drawer-title">Chestionar</div>}
                    placement="bottom"
                    closable={true}
                    height={window.innerHeight - 100}
                    visible={isModalFeedbackVisible}
                    onClose={() => setIsModalFeedbackVisible(false)}>
                    <FeedbackEvent
                        decoded={decoded}
                        restaurantDetails={restaurantDetails}
                        isModalFeedbackVisible={isModalFeedbackVisible}
                        setIsModalFeedbackVisible={setIsModalFeedbackVisible}
                        onClose={() => setIsModalFeedbackVisible(false)}
                        storageFeedbackSubmitted={storageFeedbackSubmitted}
                        storageFeedbackSubmittedTimestamp={storageFeedbackSubmittedTimestamp}
                        isFeedbackSubmitted={JSON.parse(localStorage.getItem(storageFeedbackSubmitted))}
                        setIsFeedbackSubmitted={setIsFeedbackSubmitted}
                        setFeedbackSubmittedTimestamp={setFeedbackSubmittedTimestamp}
                        timeLeftSubmitFeedback={timeLeftSubmitFeedback}
                        setTimeLeftSubmitFeedback={setTimeLeftSubmitFeedback}
                    />
                </Drawer>
                <Drawer
                    title={t('ID_POFTIGO_CALL_WAITER')}
                    placement="bottom"
                    closable={true}
                    height={350}
                    visible={isModalCallWaiterVisible}
                    onClose={() => setIsModalCallWaiterVisible(false)}>
                    <CallWaiterMessage
                        restaurantDetails={restaurantDetails}
                        decoded={decoded}
                        onClose={() => setIsModalCallWaiterVisible(false)}
                        isModalCallWaiterVisible={isModalCallWaiterVisible}
                        storageWaiterCalled={storageWaiterCalled}
                        storageWaiterCalledTimestamp={storageWaiterCalledTimestamp}
                        setIsWaiterCalled={setIsWaiterCalled}
                        setWaiterCalledTimestamp={setWaiterCalledTimestamp}
                        setCallWaiterLoading={setCallWaiterLoading}
                    />
                </Drawer>
                <Drawer
                    title={t('ID_POFTIGO_REQUEST_PAYMENT')}
                    placement="bottom"
                    closable={true}
                    height={450}
                    visible={isModalRequestPaymentVisible}
                    onClose={() => setIsModalRequestPaymentVisible(false)}>
                    <RequestPayment
                        restaurantDetails={restaurantDetails}
                        decoded={decoded}
                        onClose={() => setIsModalRequestPaymentVisible(false)}
                        isVisible={isModalRequestPaymentVisible}
                        storageRequestPayment={storageRequestPayment}
                        storageRequestPaymentTimestamp={storageRequestPaymentTimestamp}
                        setIsRequestPayment={setIsRequestPayment}
                        setIsModalFeedbackVisible={setIsModalFeedbackVisible}
                        setRequestPaymentTimestamp={setRequestPaymentTimestamp}
                        isFeedbackSubmitted={JSON.parse(localStorage.getItem(storageFeedbackSubmitted))}
                    />
                </Drawer>
                <a
                    title="Poftigo"
                    className="stickyPoftigo"
                    onClick={() => {
                        setDrawerProps({ ...drawerProps, visible: true });
                        logEvent(analytics, 'select_content', {
                            content_type: 'button',
                            item_id: 'stickyPoftigo',
                        });
                    }}>
                    <div className="stickyPoftigoInner">
                        <LogoMark />
                    </div>
                </a>
                {restaurantDetails ? (
                    restaurantDetails.statusCode !== 0 ? (
                        <>
                            <Row>
                                <Col
                                    xs={{ span: 22, offset: 1 }}
                                    md={{ span: 20, offset: 2 }}
                                    lg={{ span: 18, offset: 3 }}
                                    xl={{ span: 18, offset: 3 }}
                                    xxl={{ span: 16, offset: 4 }}
                                    className="contentRow">
                                    {isLoading ? (
                                        <>
                                            <div className="preloaderLogo">
                                                <LogoFull />
                                            </div>
                                            <Skeleton active className="loader" />
                                        </>
                                    ) : (
                                        <>
                                            <div className="coverImagePublicContainer" onClick={refreshPage}>
                                                {restaurantDetails.coverImagePath ? (
                                                    <img
                                                        src={ApiHelpers.getImageLink(restaurantDetails.coverImagePath)}
                                                        title={restaurantDetails.restaurantName}
                                                        alt={restaurantDetails.restaurantName}
                                                        className="coverImagePublic"
                                                    />
                                                ) : null}
                                                {restaurantDetails.logoPath ? (
                                                    <img
                                                        src={ApiHelpers.getImageLink(restaurantDetails.logoPath)}
                                                        title={restaurantDetails.restaurantName}
                                                        alt={restaurantDetails.restaurantName}
                                                        className="logoImagePublic"
                                                    />
                                                ) : null}
                                            </div>
                                            <h1 className="restaurantHeaderTitle" onClick={refreshPage}>
                                                {restaurantDetails.restaurantName}
                                            </h1>
                                            <Search
                                                handleSearch={handleSearch}
                                                keyword={keyword}
                                                setKeyword={setKeyword}
                                                setIsLoading={setIsLoading}
                                            />
                                            {menuCategories.length > 0 ? (
                                                <Row className="restaurantMenuContainer headline" justify="space-between">
                                                    <Col></Col>
                                                </Row>
                                            ) : null}

                                            {categorySelected.active !== true ? (
                                                <Row className="restaurantMenuContainer" gutter={[30, 30]}>
                                                    {menuCategories &&
                                                        menuCategories.map((menuCategory, index) => {
                                                            return (
                                                                <MenuCategory
                                                                    menuCategory={menuCategory}
                                                                    key={menuCategory.menuCategoryToken}
                                                                    handleClickCategory={handleClickCategory}
                                                                />
                                                            );
                                                        })}
                                                </Row>
                                            ) : (
                                                <MenuCategoryNav
                                                    menuCategory={categorySelected.categoryName}
                                                    handleClickCategory={handleClickCategory}
                                                />
                                            )}

                                            <Row className="restaurantMenuContainer" gutter={[8, 20]}>
                                                {menuCategories.length > 0 || foundMenuItems.length > 0 ? (
                                                    <>
                                                        {categorySelected.active !== true && isSearch === true ? (
                                                            <Col span={24}>
                                                                <h2 className="subTitle">Preparate</h2>
                                                            </Col>
                                                        ) : null}
                                                        <Col span={24}>
                                                            <Row justify="space-between">{listFoundMenuItemsByVendor(foundMenuItems)}</Row>
                                                        </Col>
                                                        {categorySelected.active === true && foundMenuItems.length > 0 ? (
                                                            <small>{t('ID_POFTIGO_ALLERGENS_DISCLAIMER')}</small>
                                                        ) : null}
                                                    </>
                                                ) : (
                                                    <p className="infoMessage">
                                                        {t('ID_POFTIGO_SEARCH_NO_RESULTS')}.{' '}
                                                        <a onClick={() => handleSearch('')}>{t('ID_POFTIGO_SEARCH_NO_RESULTS_CTA')}</a>
                                                    </p>
                                                )}
                                            </Row>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <div>Not found</div>
                    )
                ) : (
                    <Spin />
                )}
            </Content>
            <AntdFooter>
                <Footer branding={true} />
            </AntdFooter>
        </Layout>
    );
};

export default Event;
