import React from 'react';
import { Col } from 'antd';
import { ApiHelpers } from '../helpers';

const MenuCategory = (props) => {
    const { menuCategory, handleClickCategory } = props;

    return (
        <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
            <div className="menuCategoryItem" onClick={() => handleClickCategory(menuCategory.menuCategoryToken, menuCategory.categoryName)}>
                <h2>{menuCategory.categoryName}</h2>
                {menuCategory.categoryImagePath ? (
                    <img
                        className="menuCategoryImage"
                        alt={menuCategory.categoryName}
                        title={menuCategory.categoryName}
                        src={ApiHelpers.getImageLinkSize(menuCategory.categoryImagePath, 'medium')}
                    />
                ) : null}
            </div>
        </Col>
    );
};

export default MenuCategory;
