import React from 'react';
const d = new Date();
const year = d.getFullYear();

const Footer = (props) => {
    return !props.branding ? (
        <div className="footer">Poftigo © {year}</div>
    ) : (
        <div className="footer">
            powered by{' '}
            <a href="https://www.poftigo.ro" title="Poftigo" target="_blank">
                Poftigo
            </a>{' '}
            © {year}
        </div>
    );
};

export default Footer;
