import * as ApiHelpers from './ApiHelpers';
import Decryptor from './Decryptor';
import useWindowDimensions from './useWindowDimensions';
import useTimeout from './useTimeout';
import { RightOutlined, SwapRightOutlined } from '@ant-design/icons';
import { t } from 'i18next';

const normalizeString = (string) => {
    return string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
};

function sortByKey(array, key, asc = 1) {
    return array.sort(function (a, b) {
        var x = asc == 1 ? a[key] : b[key];
        var y = asc == 1 ? b[key] : a[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}
export const formatNutritionalValues = (value, isString = false) => {
    if (isString && value) {
        return parseFloat(value.split(' ')[3]).toFixed(2) + ' kCal / ' + parseFloat(value.split(' ')[0]).toFixed(2) + ' kJ';
    } else {
        return parseFloat(value).toFixed(2);
    }
};

export const formatMenuItemWeight = (weight) => {
    if (weight.indexOf('g') > -1 || weight.indexOf('gr') > -1 || weight.indexOf('ml') > -1) return weight;
    else return weight + ' g';
};

export const uniqueValuesinArray = (array) => {
    return array.filter((item, pos) => array.indexOf(item) === pos);
};

export const formatAllergensAndAdditives = (item) => {
    return item.split(',').join('; ');
};

export const formatFoodAdditives = (item) => {
    let result = [];
    if (item.displayFoodAdditives) {
        result = item.displayFoodAdditives.split(',');
        result = result.map((d) => d.slice(d.indexOf('/') + 1));
    } else {
        result = item.foodAdditives.split(',');
    }
    return result;
};

const renderMenuItemIngredientShort = (item, index, length, unitOfMeasure, isChild = 0, parentQuantity = null, parentSum) => {
    let componentsSumQuantity = 0;
    const hasAllergensOrAdditives = item.foodAdditives !== null || item.allergens ? true : false;
    const hasAllergens = item.allergens || (item.components && item.components.filter((d) => d.allergens).length > 0);
    if (item.components && item.components.length > 0) item.components.map((d) => (componentsSumQuantity += d.quantity));

    return (
        <span key={'ingredient-' + item.uniqueIdentificationToken}>
            {index > 0 && index < length && !isChild ? ', ' : isChild && index > 0 && hasAllergens ? '; ' : ''}

            {!isChild ? hasAllergens ? <strong>{item.title}</strong> : item.title : null}

            {item.allergens ? (
                <span>
                    {!isChild || (isChild && index === 0) ? t('ID_POFTIGO_NUTRITIONAL_ALLERGENS') : ''} {formatAllergensAndAdditives(item.allergens)}
                </span>
            ) : null}

            {item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                      return renderMenuItemIngredientShort(d, index, item.components.length, unitOfMeasure, 1, item.quantity, componentsSumQuantity);
                  })
                : null}
        </span>
    );
};

export const nutritionalMenuShort2 = (nutritionalData, record, embed = false) => {
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

    let listOfIngredientsSorted = [];
    let nutritionalValues = [];
    let noOfAdditives = 0;
    let uniqueAdditives = [];
    let noOfUniqueAdditives = 0;
    let allergensInComponents = [];
    let foodAdditivesExtractE = [];
    nutritionalDataSorted.map((item, index) => {
        if (item.displayFoodAdditives) {
            uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item)];
            noOfAdditives += item.foodAdditives.split(',').length;
        }
        if (item.components && item.components.length > 0) {
            item.components.map((item2) => {
                if (item2.displayFoodAdditives) {
                    uniqueAdditives = [...uniqueAdditives, ...formatFoodAdditives(item2)];
                    noOfAdditives += item2.foodAdditives.split(',').length;
                }
            });
        }
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(renderMenuItemIngredientShort(item, index, nutritionalDataSorted.length, unitOfMeasure));
    });
    uniqueAdditives = uniqueValuesinArray(uniqueAdditives);
    noOfUniqueAdditives = uniqueAdditives.filter(function (v, i) {
        return i == uniqueAdditives.lastIndexOf(v);
    }).length;

    nutritionalValues.push(
        <div>
            <h5>Valori nutritionale / 100g</h5>
            Valoare energetica: {formatNutritionalValues(record.energy, true)} | Grasimi: {formatNutritionalValues(record.fat)} g | acizi grasi
            saturati: {formatNutritionalValues(record.saturates)} g | Glucide: {formatNutritionalValues(record.carbohydrate)} g | Zaharuri:{' '}
            {formatNutritionalValues(record.sugars)} g | Sare: {formatNutritionalValues(record.salt)} g
        </div>
    );
    return (
        <div className="nutritional-container">
            <h3>{record.title}</h3>

            <div className={'nutritional-container-ingredients' + (embed === false ? ' overflow-active' : '')}>
                {record.itemDescription ? record.itemDescription : null}
                {record.itemDescription ? <br /> : null}
                {listOfIngredientsSorted} {noOfUniqueAdditives ? ' - ' + noOfUniqueAdditives + 'E' : null}
            </div>

            {embed === false ? (
                <div className="cta-nutritional">
                    {t('ID_POFTIGO_NUTRITIONAL_LINK')} <SwapRightOutlined />
                </div>
            ) : (
                <div>{nutritionalValues}</div>
            )}
            {embed === true && record.freezedIngredientsUsed ? (
                <div>
                    <small>Poate proveni din produs congelat</small>
                </div>
            ) : null}
            {embed === true ? <small>Ingredientele îngroșate conțin sau pot conține alergeni. </small> : null}
        </div>
    );
};

const renderMenuItemIngredient = (item, index, length, unitOfMeasure, isChild = 0, parentQuantity = null, parentSum) => {
    let componentsSumQuantity = 0;
    const hasAllergens = item.allergens || (item.components && item.components.filter((d) => d.allergens).length > 0);
    if (item.components && item.components.length > 0) item.components.map((d) => (componentsSumQuantity += d.quantity));
    return (
        <span key={'ingredient-' + item.uniqueIdentificationToken}>
            {index > 0 && index < length ? ', ' : isChild && index > 0 && hasAllergens ? '; ' : ''}

            {item.allergens ? <strong>{item.title}</strong> : item.title}
            {item.allergens ? (
                <span>
                    {t('ID_POFTIGO_NUTRITIONAL_ALLERGENS')}
                    {formatAllergensAndAdditives(item.allergens)}
                </span>
            ) : null}
            {item.foodAdditives ? (
                <span>
                    {t('ID_POFTIGO_NUTRITIONAL_FOOD_ADDITIVES')}
                    {formatAllergensAndAdditives(item.displayFoodAdditives)}
                </span>
            ) : null}

            {item.components && item.components.length > 0 ? ' (' : ''}

            {item.components && item.components.length > 0
                ? sortByKey(item.components, 'quantity', 0).map((d, index) => {
                      return renderMenuItemIngredient(d, index, item.components.length, unitOfMeasure, 1, item.quantity, componentsSumQuantity);
                  })
                : null}
            {item.components && item.components.length > 0 ? ')' : ''}
        </span>
    );
};
export const nutritionalCalculationSumIngredients = (nutritionalData) => {
    let totalQuantity = 0;
    if (nutritionalData.length > 0)
        nutritionalData.map((d) => {
            totalQuantity += d.quantity;
        });
    return totalQuantity > 0 ? '(' + totalQuantity + ' g)' : '';
};

export const nutritionalCalculation = (nutritionalData, type = '100g') => {
    let totalQuantity = 0;
    const calculation = {
        energy: null,
        quantity: null,
        fat: null,
        saturates: null,
        proteins: null,
        salt: null,
        sugars: null,
        carbohydrate: null,
        key: null,
        energyKJ: null,
        energyKCal: null,
        monounsaturates: 0,
        polyunsaturates: 0,
        polyols: 0,
        starch: 0,
        fiber: 0,
    };
    const calculation100g = {
        energy: null,
        quantity: null,
        fat: null,
        saturates: null,
        proteins: null,
        salt: null,
        sugars: null,
        carbohydrate: null,
        key: null,
        energyKJ: null,
        energyKCal: null,
        monounsaturates: 0,
        polyunsaturates: 0,
        polyols: 0,
        starch: 0,
        fiber: 0,
    };
    if (nutritionalData.length > 0) {
        nutritionalData.map((d) => {
            totalQuantity += d.quantity;
        });
        const quantityFactor = parseFloat((totalQuantity / 100).toFixed(2));
        nutritionalData.map((d) => {
            calculation.quantity += d.quantity;
            ['fat', 'saturates', 'proteins', 'salt', 'sugars', 'carbohydrate'].map((field) => {
                calculation[field] += (parseFloat(d[field]).toFixed(2) * d.quantity) / 100;
            });
        });
        // Calculate energy values based on sum
        calculation.energyKJ =
            17 * calculation.carbohydrate + 37 * calculation.fat + 10 * calculation.polyols + 17 * calculation.proteins + 8 * calculation.fiber;
        calculation.energyKCal =
            4 * calculation.carbohydrate + 9 * calculation.fat + 2.4 * calculation.polyols + 4 * calculation.proteins + 2 * calculation.fiber;
        calculation.energy = calculation.energyKJ + ' kJ / ' + calculation.energyKCal + ' kCal';
        ['fat', 'saturates', 'proteins', 'salt', 'sugars', 'carbohydrate'].map(
            (field) => (calculation100g[field] += parseFloat(calculation[field]).toFixed(2) / quantityFactor)
        );
        // Calculate energy values based on sum
        calculation100g.energyKJ =
            17 * calculation100g.carbohydrate +
            37 * calculation100g.fat +
            10 * calculation100g.polyols +
            17 * calculation100g.proteins +
            8 * calculation100g.fiber;
        calculation100g.energyKCal =
            4 * calculation100g.carbohydrate +
            9 * calculation100g.fat +
            2.4 * calculation100g.polyols +
            4 * calculation100g.proteins +
            2 * calculation100g.fiber;
        calculation100g.energy = calculation100g.energyKJ + ' kJ / ' + calculation100g.energyKCal + ' kCal';
    }
    return type === '100g' ? calculation100g : calculation;
};

export const nutritionalMenuFull = (nutritionalData, record, embed = false) => {
    const nutritionalDataSorted = sortByKey(nutritionalData, 'quantity', 0);

    let listOfIngredientsSorted = [];
    let nutritionalValues = [];
    let nutritionalCalculationSum = nutritionalCalculation(nutritionalData, 'full');
    nutritionalDataSorted.map((item, index) => {
        const unitOfMeasure = item.unitOfMeasure || 'g';
        listOfIngredientsSorted.push(renderMenuItemIngredient(item, index, nutritionalDataSorted.length, unitOfMeasure));
    });
    nutritionalValues.push(
        <div key="values100">
            <h5>{t('ID_POFTIGO_NUTRITIONAL_VALUES_100g')}</h5>
            {t('ID_POFTIGO_NUTRITIONAL_ENERGY_TITLE')} {formatNutritionalValues(record.energy, true)}
            {t('ID_POFTIGO_NUTRITIONAL_FATS')}
            {formatNutritionalValues(record.fat)} g {t('ID_POFTIGO_NUTRITIONAL_FATS1')}
            {formatNutritionalValues(record.saturates)} g {t('ID_POFTIGO_NUTRITIONAL_CARBS')}
            {formatNutritionalValues(record.carbohydrate)} g {t('ID_POFTIGO_NUTRITIONAL_SUGARS')} {formatNutritionalValues(record.sugars)} g{' '}
            {t('ID_POFTIGO_NUTRITIONAL_PROTEINS')}
            {formatNutritionalValues(record.proteins)} g {t('ID_POFTIGO_NUTRITIONAL_SALT')} {formatNutritionalValues(record.salt)} g
        </div>
    );

    return (
        <div className="nutritional-container">
            {embed === false ? <h3>{record.title}</h3> : null}
            <div className={'nutritional-container-ingredients' + (embed === false ? ' overflow-active' : '')}>{listOfIngredientsSorted}</div>
            {embed === false ? (
                <div className="cta-nutritional">
                    {t('ID_POFTIGO_NUTRITIONAL_LINK')} <SwapRightOutlined />
                </div>
            ) : (
                <div>{nutritionalValues}</div>
            )}
            {embed === true && record.freezedIngredientsUsed ? (
                <div>
                    <small>{t('ID_POFTIGO_FREEZED_DISCLAIMER')}</small>
                </div>
            ) : null}
            {embed === true ? <small>{t('ID_POFTIGO_ALLERGENS_DISCLAIMER')}</small> : null}
        </div>
    );
};

export { Decryptor, ApiHelpers, normalizeString, useWindowDimensions, useTimeout };
