import React from 'react';
import { Col, Row } from 'antd';
import { ApiHelpers, nutritionalMenuFull, formatMenuItemWeight, nutritionalMenuShort2 } from '../helpers';

const MenuItem = (props) => {
    const { menuItem, showModal } = props;

    return (
        <Col
            xs={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 0 }}
            xl={{ span: 11, offset: 0 }}
            xxl={{ span: 11, offset: 0 }}>
            <Row className="menuItemContainer" gutter={[10, 20]} onClick={() => showModal(menuItem)}>
                <Col span={menuItem.itemImagePath ? 14 : 24}>
                    {menuItem.listOfIngredients === null ? (
                        <>
                            <h3>{menuItem.title}</h3>
                            <p className="description">{menuItem.itemDescription}</p>
                            <p className="ingredients">{menuItem.ingredients}</p>
                        </>
                    ) : (
                        <div>{nutritionalMenuShort2(menuItem.listOfIngredients, menuItem)}</div>
                    )}

                    <p className="price">
                        {menuItem.price} lei {menuItem.weight ? <span>({formatMenuItemWeight(menuItem.weight)})</span> : null}
                    </p>
                </Col>
                {menuItem.itemImagePath ? (
                    <Col span={10}>
                        {menuItem.itemImagePath ? (
                            <img
                                src={ApiHelpers.getImageLinkSize(menuItem.itemImagePath, 'medium')}
                                alt={menuItem.title}
                                title={menuItem.title}
                                className="menuItemImage"
                            />
                        ) : null}
                    </Col>
                ) : null}
            </Row>
        </Col>
    );
};

export default MenuItem;
