import React, { useState } from 'react';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { firebaseConfig } from '../config';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useTranslation } from 'react-i18next';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Search = (props) => {
    const { handleSearch, keyword, setKeyword } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const spinIcon = <LoadingOutlined style={{ fontSize: 24, color: '#EE4F34' }} spin />;

    // Debounce callback
    const debounced = useDebouncedCallback((value) => {
        handleSearch(value);
    }, 1500
    );

    const handleChange = (value) => {
        handleSearch(value);
    }

    const onSearch = (e) => {
        setKeyword(e.target.value);
        setIsLoading(true);
        debounced(e.target.value)
    }

    return (
        <div className="searchBar">
            <div className="inner">
                <div className="searchInputContainer">
                    <SearchOutlined
                        className="searchIcon"
                        onClick={() => handleSearch(keyword)}
                        style={{ fontSize: 18, color: '#555', display: 'inline' }} />

                    <input type="text"
                        placeholder={t('ID_POFTIGO_SEARCH_MENU_PLACEHOLDER')}
                        onChange={(e) => { onSearch(e) }}
                        value={keyword}
                    //onChange={(e) => handleSearch(e.target.value)}
                    //value={keyword}
                    />

                </div>
                {isLoading ? <Spin indicator={spinIcon} /> :
                    keyword ?
                        <CloseCircleFilled onClick={() => handleChange("")} style={{ color: '#888' }} />
                        : null
                }
            </div>
        </div>
    )
}

export default Search;