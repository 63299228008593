import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Input, message,Radio } from 'antd';
import { ApiHelpers } from '../helpers';
import { useTranslation } from 'react-i18next';
import { firebaseConfig } from '../config';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const { TextArea } = Input;

const RequestPayment = (props) => {
    const { t } = useTranslation();
    const {
        restaurantDetails,
        isVisible,
        onClose,
        storageRequestPayment,
        storageRequestPaymentTimestamp,
        setIsRequestPayment,
        decoded,
        setIsModalFeedbackVisible,
        setRequestPaymentTimestamp,
        isFeedbackSubmitted,
    } = props;

    const availablePaymentMethods = [
        { label: t('ID_POFTIGO_REQUEST_PAYMENT_OPTION1'), value: 'cash' },
        { label: t('ID_POFTIGO_REQUEST_PAYMENT_OPTION2'), value: 'card' },
        { label: t('ID_POFTIGO_REQUEST_PAYMENT_OPTION3'), value: 'other' },
    ];
    const availableTipsMethods = ['0%', '5%', '10%', '15%'];

    const INITIAL_PAYMENT_MODEL = {
        restaurantToken: restaurantDetails.uniqueIdentificationToken,
        paymentMethod: '',
        tip: '',
        customTip: '',
    };
    const [paymentContent, setPaymentContent] = useState({ ...INITIAL_PAYMENT_MODEL });
    const [isLoading, setIsLoading] = useState(false);
    const onChangePaymentMethod = ({ target: { value } }) => {
        setPaymentContent({ ...paymentContent, paymentMethod: value });
    };

    const onChangeTips = ({ target: { value } }) => {
        setPaymentContent({ ...paymentContent, tip: value, customTip: '' });
    };

    const onFocusCustomTips = () => {
        setPaymentContent({ ...paymentContent, tip: 'x' });
    };

    const onChangeCustomTips = (event) => {
        const result = parseInt(event.target.value) ? parseInt(event.target.value) : '';
        setPaymentContent({ ...paymentContent, tip: 'x', customTip: result });
    };

    useEffect(() => {
        setPaymentContent({ ...INITIAL_PAYMENT_MODEL });
    }, [isVisible]);

    const onSubmit = () => {
        setIsLoading(true);
        ApiHelpers.requestBill(
            restaurantDetails.uniqueIdentificationToken,
            decoded.TableCode,
            paymentContent.paymentMethod,
            paymentContent.tip === 'x' ? paymentContent.customTip : paymentContent.tip
        ).then(
            (response) => {
                if (response.status === 200) {
                    setIsLoading(false);
                    logEvent(analytics, 'select_content', {
                        content_type: 'button',
                        item_id: 'requestPayment',
                    });
                    localStorage.setItem(storageRequestPayment, JSON.stringify(true));
                    localStorage.setItem(storageRequestPaymentTimestamp, JSON.stringify(new Date().getTime()));
                    setIsRequestPayment(true);
                    setRequestPaymentTimestamp(new Date());
                    onClose();
                    message.success(t('ID_POFTIGO_NOTIFICATION_CALL_WAITER_SUCCESS'));

                    if (!isFeedbackSubmitted) setIsModalFeedbackVisible(true);
                }
            },
            (error) => {
                message.error(t('ID_POFTIGO_ERROR_MESSAGE'));
                setIsLoading(false);
            }
        );
    };

    return (
        <>
            {restaurantDetails.subscriptionPlan === 'Full' || restaurantDetails.subscriptionPlan === 'Appetizer' ? (
                <>
                    <Row className="request-payment-container">
                        <Col>
                            <h3>{t('ID_POFTIGO_REQUEST_PAYMENT_TEXT1')}</h3>
                            <div className="section">
                                <Radio.Group
                                    options={availablePaymentMethods}
                                    onChange={onChangePaymentMethod}
                                    value={paymentContent.paymentMethod}
                                    optionType="button"
                                />
                            </div>

                            <h3>{t('ID_POFTIGO_REQUEST_PAYMENT_TEXT2')}</h3>

                            <div>
                                <Radio.Group onChange={onChangeTips} value={paymentContent.tip}>
                                    <Radio.Button value={availableTipsMethods[0]}>😞 {availableTipsMethods[0]}</Radio.Button>
                                    <Radio.Button value={availableTipsMethods[1]}>🙂 {availableTipsMethods[1]}</Radio.Button>
                                    <Radio.Button value={availableTipsMethods[2]}>🤩 {availableTipsMethods[2]}</Radio.Button>
                                    <Radio.Button value={availableTipsMethods[3]}>🥰 {availableTipsMethods[3]}</Radio.Button>
                                    <Radio.Button value="x">
                                        <input
                                            className={`custom-amount ${
                                                paymentContent.customTip > 0 ? (paymentContent.customTip < 99 ? ' small' : 'medium') : ''
                                            }`}
                                            placeholder={t('ID_POFTIGO_REQUEST_PAYMENT_TIPS_PH')}
                                            value={paymentContent.customTip}
                                            onChange={onChangeCustomTips}
                                            onFocus={onFocusCustomTips}
                                        />
                                        {paymentContent.customTip > 0 ? 'lei' : null}
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                            <Button
                                type="primary"
                                size="large"
                                loading={isLoading}
                                onClick={onSubmit}
                                className="buttonModalClose buttomSubmit"
                                disabled={paymentContent.paymentMethod ? false : true}>
                                {t('ID_POFTIGO_SUBMIT')}
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </>
    );
};

export default RequestPayment;
