import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_US from '../const/translations/en_US';
import ro_RO from '../const/translations/ro_RO';

//empty for now
const resources = {
    'en-US': {
        translation: en_US
    },
    'ro-RO': {
        translation: ro_RO
    }
};

i18n.use(initReactI18next)
.init({
    react:{
        useSuspense: false
    },
    compatibilityJSON: 'v3',
    resources: resources,
    //language to use if translations in user language are not available
    fallbackLng: "ro-RO",
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
});

export default i18n;