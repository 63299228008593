import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Input, message } from 'antd';
import { ApiHelpers } from '../helpers';
import { useTranslation } from 'react-i18next';
import Rating from 'react-rating';

import iconStar from '../assets/img/star.png';
import iconStarFilled from '../assets/img/star-filled.png';
const { TextArea } = Input;

const Feedback = (props) => {
    const { t } = useTranslation();
    const {
        restaurantDetails,
        isModalFeedbackVisible,
        setIsModalFeedbackVisible,
        onClose,
        isFeedbackSubmitted,
        setIsFeedbackSubmitted,
        setFeedbackSubmittedTimestamp,
        storageFeedbackSubmitted,
        storageFeedbackSubmittedTimestamp,
        decoded
    } = props;
    const INITIAL_FEEDBACK_MODEL = {
        restaurantToken: restaurantDetails.uniqueIdentificationToken,
        score: '',
        foodScore: '',
        waiterScore: '',
        uuid: '',
        feedbackMessage: '',
        tableToken: decoded.TableCode
    };
    const [feedbackContent, setFeedbackContent] = useState({ ...INITIAL_FEEDBACK_MODEL });

    useEffect(() => {
        setFeedbackContent({ ...INITIAL_FEEDBACK_MODEL });
    }, [isModalFeedbackVisible]);

    const onSubmitFeedback = () => {
        ApiHelpers.submitFeedback(restaurantDetails.uniqueIdentificationToken, feedbackContent).then(
            (response) => {
                if (response.status === 200) {
                    localStorage.setItem(storageFeedbackSubmitted, JSON.stringify(true));
                    localStorage.setItem(storageFeedbackSubmittedTimestamp, JSON.stringify(new Date().getTime()));
                    setIsFeedbackSubmitted(true);
                    setFeedbackSubmittedTimestamp(new Date());
                    onClose();
                    message.success(t('ID_POFTIGO_FEEDBACK_SUBMIT_SUCCESS'));
                }
            },
            (error) => {
                message.error(t('ID_POFTIGO_ERROR_MESSAGE'));
            }
        );
    };

    return (
        <>
            {restaurantDetails.subscriptionPlan === 'Full' || restaurantDetails.subscriptionPlan === 'Appetizer' || restaurantDetails.subscriptionPlan === 'AppetizerF' ? (
                <>
                    <Row className="feedback-container">
                        <Col>
                            <p>
                                {t('ID_POFTIGO_FEEDBACK_TEXT1') + restaurantDetails.restaurantName + '?'} {t('ID_POFTIGO_FEEDBACK_TEXT2')}
                            </p>
                            <h3>{t('ID_POFTIGO_FEEDBACK_SECTION_FOOD')}</h3>
                            <Rating
                                onChange={(rate) => setFeedbackContent({ ...feedbackContent, foodScore: rate })}
                                initialRating={feedbackContent.foodScore}
                                emptySymbol={<img title="star" alt="star" src={iconStar} className="rating-icon" />}
                                fullSymbol={<img title="star" alt="star" src={iconStarFilled} className="rating-icon" />}
                            />
                            <h3>{t('ID_POFTIGO_FEEDBACK_SECTION_RESTAURANT')}</h3>
                            <Rating
                                onChange={(rate) => setFeedbackContent({ ...feedbackContent, score: rate })}
                                initialRating={feedbackContent.score}
                                emptySymbol={<img src={iconStar} title="star" alt="star" className="rating-icon" />}
                                fullSymbol={<img src={iconStarFilled} title="star" alt="star" className="rating-icon" />}
                            />
                            <h3>{t('ID_POFTIGO_FEEDBACK_SECTION_WAITER')}</h3>
                            <Rating
                                onChange={(rate) => setFeedbackContent({ ...feedbackContent, waiterScore: rate })}
                                initialRating={feedbackContent.waiterScore}
                                emptySymbol={<img src={iconStar} title="star" alt="star" className="rating-icon" />}
                                fullSymbol={<img src={iconStarFilled} title="star" alt="star" className="rating-icon" />}
                            />
                            <TextArea
                                placeholder={t('ID_POFTIGO_FEEDBACK_COMMENTS_PLACEHOLDER')}
                                className="comments-box"
                                onChange={(e) => {
                                    setFeedbackContent({ ...feedbackContent, feedbackMessage: e.target.value });
                                }}
                                value={feedbackContent.feedbackMessage}
                            />
                            <Button
                                type="primary"
                                size="large"
                                onClick={onSubmitFeedback}
                                className="buttonModalClose buttomSubmit"
                                disabled={feedbackContent.score && feedbackContent.foodScore && feedbackContent.waiterScore ? false : true}>
                                {t('ID_POFTIGO_SUBMIT')}
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : null}
        </>
    );
};

export default Feedback;
