import React from 'react';
import { Col, Row, Button } from 'antd';
import { ApiHelpers, formatMenuItemWeight, nutritionalMenuShort2 } from '../helpers'

const formatTitle = (title) => {
    return title.indexOf('#') > -1 ? title.substring(title.indexOf('#') + 1) : title;
};

const MenuItemEvent = (props) => {
    const { menuItem, showModal, showCurrentVendor, currentVendor, index, setIsModalFeedbackVisible, restaurantDetails } = props;

    return (
        <>
            {showCurrentVendor ? (
                <Col span={24}>
                    {index > 0 && localStorage.getItem('surveySubmitted_' + restaurantDetails.uniqueIdentificationToken) !== 'true' ? (
                        <Button type="primary" size="large" onClick={() => setIsModalFeedbackVisible(true)} className="buttonModalClose buttonSurvey">
                            Completează chestionarul și câștigă o bere
                        </Button>
                    ) : null}
                    <h4 className="vendor-name">{currentVendor}</h4>
                </Col>
            ) : null}
            <Col
                xs={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                xl={{ span: 11, offset: 0 }}
                xxl={{ span: 11, offset: 0 }}>
                <Row className="menuItemContainer" gutter={[10, 20]} onClick={() => showModal(menuItem)}>
                    <Col span={menuItem.itemImagePath ? 14 : 24}>
                        {menuItem.listOfIngredients === null ? (
                            <>
                                <h3>{formatTitle(menuItem.title)}</h3>
                                <p className="description">{menuItem.itemDescription}</p>
                                <p className="ingredients">{menuItem.ingredients}</p>
                            </>
                        ) : (
                            <div>{nutritionalMenuShort2(menuItem.listOfIngredients, { ...menuItem, title: formatTitle(menuItem.title) })}</div>
                        )}

                        <p className="price">
                            {menuItem.price} lei {menuItem.weight ? <span>({formatMenuItemWeight(menuItem.weight)})</span> : null}
                        </p>
                    </Col>
                    {menuItem.itemImagePath ? (
                        <Col span={10}>
                            {menuItem.itemImagePath ? (
                                <img
                                    src={ApiHelpers.getImageLink(menuItem.itemImagePath)}
                                    alt={menuItem.title}
                                    title={menuItem.title}
                                    className="menuItemImage"
                                />
                            ) : null}
                        </Col>
                    ) : null}
                </Row>
            </Col>
        </>
    );
};

export default MenuItemEvent;
